<template>
    <div class="mainSpyContainer">

        <Zafer></Zafer>
    </div>
</template>

<script>
    import Zafer from './Zafer';

    export default {
        components: {
            Zafer
        }
    }
</script>



